import React, { Component } from "react"

// Styles
import CommonStyles from "~components/Panels/Home/PanelCommons.module.styl"
import Styles from "~components/Panels/Resultats/PanelProfil.module.styl"
import SVGStyles from "~components/SVGs/SVGProfil.module.styl"

// Components
import { Row, Cell } from "~layout/Layout"

// Image
import Image from "~images/profil-404.svg"

class PanelProfil extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={`${CommonStyles.PanelWhite} ${Styles.PanelProfil}`}>
                <Row>
                    <Cell start="0" end="10">
                        <div className={Styles.PanelProfil__inner}>
                            <div className={Styles.PanelProfil__left}>
                                <h2 className="heading-4">Votre profil</h2>
                                <div
                                    className={`${Styles.PanelProfil__left__families}`}
                                >
                                    <p
                                        className={`${Styles.PanelProfil__left__families__item}`}
                                    >
                                        Majeure :{" "}
                                        <span className={`graph-legend`}>
                                            Cassé
                                        </span>
                                    </p>
                                    <p
                                        className={`${Styles.PanelProfil__left__families__item}`}
                                    >
                                        Mineure :{" "}
                                        <span className={`graph-legend`}>
                                            Pioche
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className={Styles.PanelProfil__right}>
                                <div
                                    className={
                                        Styles.PanelProfil__right__wrapper
                                    }
                                >
                                    <div
                                        className={
                                            Styles.PanelProfil__graph__labels
                                        }
                                    >
                                        <div
                                            className={`${Styles.PanelProfil__graph__labels__top}`}
                                        >
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__center}`}
                                            >
                                                Vision
                                            </p>
                                        </div>
                                        <div
                                            className={`${Styles.PanelProfil__graph__labels__center}`}
                                        >
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__left}`}
                                            >
                                                Business
                                            </p>
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__right}`}
                                            >
                                                Service
                                            </p>
                                        </div>
                                        <div
                                            className={`${Styles.PanelProfil__graph__labels__bottom}`}
                                        >
                                            <p
                                                className={`graph-label graph-label--small ${Styles.PanelProfil__graph__label__center}`}
                                            >
                                                Référence
                                            </p>
                                        </div>
                                    </div>

                                    <div
                                        className={`${SVGStyles.SVGProfil} ${SVGStyles.SVGProfil__404}`}
                                    >
                                        <img
                                            className={`${SVGStyles.SVGProfil__img}`}
                                            src={Image}
                                        />
                                        <svg
                                            className={`${SVGStyles.SVGProfil__graph}`}
                                            viewBox="0 0 12 12"
                                        >
                                            <line
                                                x1="6"
                                                y1="0"
                                                x2="6"
                                                y2="12"
                                                stroke="black"
                                                strokeWidth="1"
                                                vectorEffect="non-scaling-stroke"
                                            />
                                            <line
                                                x1="0"
                                                y1="6"
                                                x2="12"
                                                y2="6"
                                                stroke="black"
                                                strokeWidth="1"
                                                vectorEffect="non-scaling-stroke"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className={Styles.PanelProfil__legend}>
                                <ul
                                    className={Styles.PanelProfil__legend__list}
                                >
                                    <li
                                        className={`${Styles.PanelProfil__legend__list__item} ${Styles.PanelProfil__legend__list__item__project} tag u-f-upper`}
                                    >
                                        Son projet projet
                                    </li>
                                    <li
                                        className={`${Styles.PanelProfil__legend__list__item} tag u-f-upper`}
                                    >
                                        La moyenne d'un {this.props.siteName}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Cell>
                </Row>
            </div>
        )
    }
}

export default PanelProfil
