import React, { Component } from "react"
import { Link } from "gatsby"

// Layout
import { Row, Cell, View, Panel, Footer } from "~layout/Layout"

// Data
import data from "~datas/quatrecentquatre.json"

// Components
import PanelTitle from "~components/Panels/Resultats/PanelTitle"
import PanelIntro from "~components/Panels/Resultats/PanelIntro"
import PanelProfil from "~components/Panels/404/PanelProfil"
import TopBarResultat from "~components/TopBars/TopBarResultat"
import PanelReussir from "~components/Panels/Resultats/PanelReussir"
import PanelRisques from "~components/Panels/Resultats/PanelRisques"
import PanelPriorites from "~components/Panels/Resultats/PanelPriorites"
import PanelPlusLoin from "~components/Panels/Resultats/PanelPlusLoin"
import Supfooter from "~components/Supfooters/Supfooter"
import CanvasBackground from "~components/Canvases/CanvasBackground"
import Metas from "~misc/Metas"

class QuatreCentQuatrePage extends Component {
    constructor(props) {
        super(props)

        this.data = data[0]
        this.sequence = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]
        this.currentIndex = -1

        // ES6
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    state = {
        isFiesta: false,
    }

    componentDidMount() {
        this.bindEvents()
    }

    componentWillUnmount() {
        this.unbindEvents()
    }

    bindEvents() {
        document.addEventListener("keydown", this.onKeyDown)
    }

    unbindEvents() {
        document.removeEventListener("keydown", this.onKeyDown)
    }

    onKeyDown(e) {
        this.currentIndex++

        if (e.keyCode === this.sequence[this.currentIndex]) {
            if (this.currentIndex === this.sequence.length - 1) {
                this.setState({
                    isFiesta: !this.state.isFiesta,
                })
            }
        } else {
            this.currentIndex = -1
        }
    }

    render() {
        return (
            <View
                name="404"
                extraClassNames={this.state.isFiesta ? "is-fiesta" : ""}
                theme={this.data.theme}
            >
                <Metas shouldIndex={false} />

                <CanvasBackground />

                <PanelTitle title={this.data.name} id={this.data.id} />

                <PanelIntro
                    chapo={this.data.chapo}
                    description={this.data.description}
                />

                <PanelProfil siteName={this.data.name} />

                <div className={"view__topbar"}>
                    <Row>
                        <Cell start="0" end="12">
                            <TopBarResultat
                                label="Accès direct"
                                data={[
                                    {
                                        name: "Réussir ce projet",
                                        slug: "reussir",
                                    },
                                    {
                                        name: "Risques et arbitrages",
                                        slug: "risques",
                                    },
                                    {
                                        name: "Les priorités",
                                        slug: "priorites",
                                    },
                                    {
                                        name: "Aller plus loin",
                                        slug: "plus_loin",
                                    },
                                ]}
                            />
                        </Cell>
                    </Row>
                </div>

                <PanelReussir
                    carouselItems={this.data.panelReussir.carouselItems}
                    detailsItems={this.data.panelReussir.detailsItems}
                    isMailWalled={false}
                />

                <div className={`view__mailwalled`}>
                    <PanelRisques
                        items={this.data.panelRisquesArbitrages.items}
                    />

                    <PanelPriorites
                        title={this.data.name}
                        items={this.data.panelPriorites.expandItems}
                    />

                    <PanelPlusLoin
                        profilID={this.data.id}
                        title={this.data.panelPlusLoin.title}
                        chapo={this.data.panelPlusLoin.chapo}
                        cta={this.data.panelPlusLoin.cta}
                        family={this.data.panelPlusLoin.carouselFamily}
                    />

                    <div className={`view__bottom`}>
                        <Supfooter />
                        <Footer />
                    </div>
                </div>
            </View>
        )
    }
}

export default QuatreCentQuatrePage
